import React from 'react'
import { ProvideEventData } from './EventContext'
import { Routes, Route } from 'react-router-dom'
import { EventUpload } from './EventUpload'
import { EventShow } from './EventShow'
import { EventLayout } from './EventLayout'

export const EventDirector = () => {
  return (
    <ProvideEventData>
      <Routes>
        <Route path="*" element={<EventLayout />}>
          <Route path="upload" element={<EventUpload />} />
          <Route path="*" element={<EventShow />} />
        </Route>
      </Routes>
    </ProvideEventData>
  )
}