import './App.css';
import { LoginModal } from './components/core/LoginModal';
import { ProvideAuth } from './components/core/ProvideAuth';
import { ProvideFirebase } from './components/core/ProvideFirebase';
import { ProvideSettings } from './components/core/ProvideSettings'
import { Wrapper } from './components/layout/Wrapper'

function App() {
  return (
    <ProvideSettings>
      <ProvideFirebase>
        <ProvideAuth>
          <Wrapper />
          <LoginModal />
        </ProvideAuth>
      </ProvideFirebase>
    </ProvideSettings>
  );
}

export default App;
