import React, { useCallback } from 'react'
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextField,
  TextInput,
  EditButton,
  Edit,
  FileInput,
  FileField,
  BooleanInput,
  BooleanField,
  FormDataConsumer,
  Loading,
  useList,
  ListContextProvider,
  FunctionField,
  Button
} from 'react-admin'
import MarkdownInput from 'ra-input-markdown';
import { ColorInput } from 'react-admin-color-input';
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useDownloadURL } from 'react-firebase-hooks/storage';

import { useFirebase } from '../core/ProvideFirebase';
import { PseudoShredder } from './PseudoShredder'
import { displayPresenterName } from '../../utils/displayPresenterName'
import { useSettings } from '../core/ProvideSettings'

export const EventsList = (props) => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="fullName" />
        <BooleanField source="public" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export const EventsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" />
        <TextInput source="fullName" />
      </SimpleForm>
    </Create>
  )
}

const PresentationRender = ({data}) => {
  const listContext = useList({
    data,
    ids: data.map(d => d.id),
  })
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <FunctionField source="presenter.lastName" render={displayPresenterName} label="Presenter" />
        <TextField source="presentationName" />
        <TextField source="sessionName" />
        <TextField source="dayName" />
        <TextField source="roomName" />
        <TextField source="startTime" />
      </Datagrid>
    </ListContextProvider>
  )
}

const PresentationBlock = ({eventPath}) => {
  const { firestore } = useFirebase()
  const [data, loading, error] = useCollectionData(
    firestore.collection(`events/${eventPath}/presentations`), 
    {idField: 'id'}
  )
  
  if (loading) {
    return <Loading />
  }
  if (error) {
    return (
      <div>
        Something went wrong: {error.toString()}
      </div>
    )
  }
  return (
    <PresentationRender data={data} />
  )
}

const DownloadZip = ({ eventPath, id }) => {
  const { firebaseStorage } = useFirebase()
  const [downloadUrl, loading, error] = useDownloadURL(firebaseStorage.ref(`events/${eventPath}/zips/${id}.zip`));
  if (loading) {
    return <Loading />
  }
  return <a href={downloadUrl}>Download</a>
}

const ZipRender = ({ data, eventPath }) => {
  const listContext = useList({
    data,
    ids: data.map(d => d.id),
  })
  return (
    <ListContextProvider value={listContext}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="createdAt" />
        <FunctionField source="ready" render={record => record.ready && <DownloadZip eventPath={eventPath} id={record.id} />} />
      </Datagrid>
    </ListContextProvider>
  )
}

const ZipBlocks = ({ eventPath }) => {
  const { firestore } = useFirebase()
  const { FIREBASE_CFG } = useSettings()
  const [data, loading, error] = useCollectionData(
    firestore.collection(`events/${eventPath}/zips`), 
    {idField: 'id'}
  )
  const createZip = () => {
    const zRef = firestore.collection(`events/${eventPath}/zips`).doc()
    zRef.set({
      createdAt: (new Date()).toISOString(),
      storageBucket: FIREBASE_CFG.storageBucket
    })
  }
  
  if (loading) {
    return <Loading />
  }
  if (error) {
    return (
      <div>
        Something went wrong: {error.toString()}
      </div>
    )
  }
  return (
    <>
      <Button onClick={createZip} label="Generate Zip File" />
      <ZipRender data={data} eventPath={eventPath} />
    </>
  )
}

export const EventsEdit = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput disabled source="id" />
          <TextInput source="fullName" />
          <MarkdownInput source="description" />
          <FileInput source="images.banner">
            <FileField source="src" title="title" />
          </FileInput>
          <ColorInput source="bannerColor" />
          <BooleanInput source="hideHeader" />
          <ColorInput source="headerBackgroundColor" />
          <ColorInput source="headerFontColor" />
          <BooleanInput source="public" />
        </FormTab>
        <FormTab label="presentations">
          <FormDataConsumer>
            {({record}) => {
              return (
                <>
                  <PseudoShredder eventPath={record.id} />
                  <PresentationBlock eventPath={record.id} />
                </>
              )
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="zips">
          <FormDataConsumer>
            {({record}) => {
              return (
                <>
                  <ZipBlocks eventPath={record.id} />
                </>
              )
            }}
          </FormDataConsumer>

        </FormTab>
      </TabbedForm>
    </Edit>
  )
}