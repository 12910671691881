import React from 'react'
import { Outlet } from 'react-router-dom'
import { useEventData } from './EventContext'
import { withStyles } from '@material-ui/core'

const DEFAULT_BANNER_COLOR = '#001191'
const DEFAULT_HEADER_BG_COLOR = '#575757'
const DEFAULT_HEADER_FG_COLOR = '#ffffff'

const styles = theme => ({
  bannerWrapper: {
    height: '100px',
    width: '100%',
    position: 'relative'
  },
  bannerImage: {
    maxHeight: '100%',
    height: '100%',
    maxWidth: '100%'
  },
  eventName: {
    minHeight: '30px',
    width: '100%',
    position: 'relative',
    padding: '10px',
    fontSize: '24px',
  },
  eventBody: {
    width: '100%',
    position: 'relative',
    padding: '10px'
  }
})
const _EventLayout = ({ classes }) => {
  const { 
    fullName, 
    images, 
    bannerColor = DEFAULT_BANNER_COLOR,
    headerBackgroundColor = DEFAULT_HEADER_BG_COLOR,
    headerFontColor = DEFAULT_HEADER_FG_COLOR,
    hideHeader = false
  } = useEventData()

  return (
    <div>
      <div className={classes.bannerWrapper} style={{backgroundColor: bannerColor}}>
        <img src={images.banner.src} className={classes.bannerImage} />
      </div>
      {
        !hideHeader && (
          <div className={classes.eventName} style={{backgroundColor: headerBackgroundColor, color: headerFontColor}}>
            <strong>
              {fullName}
            </strong>
          </div>
        )
      }
      <div className={classes.eventBody}>
        <Outlet />
      </div>
    </div>
  )
}

export const EventLayout = withStyles(styles)(_EventLayout)