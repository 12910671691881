import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { PresentationAdmin } from '../admin/PresentationAdmin'
import { EventDirector } from '../event/EventDirector';
import { RequireAuth } from '../core/RequireAuthRoute'
import { Home } from './Home'
import { Layout } from './Layout';

export const Wrapper = () => {
  // TODO: Add the Footer with the nested Route patten
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/admin" element={<RequireAuth><PresentationAdmin /></RequireAuth>} />
          <Route path="/:event_short/*" element={<EventDirector />} />
        </Route>
      </Routes>
    </Router>
  )
}