import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useEventData } from './EventContext'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

const MISSING_DESCRIPTION = 'No description provided.'

export const EventShow = () => {
  const { description = MISSING_DESCRIPTION } = useEventData()
  return (
    <>
      <ReactMarkdown children={description} />
      <Link to="upload">
        <Button variant="contained">
          Upload Presentation Files
        </Button>
      </Link>
    </>
  )
}