import React from 'react'
import { UserInfoAndLogin } from '../core/UserInfoAndLogin'

export const Footer = ({ classes }) => {
  return (
    <>
      <UserInfoAndLogin />
    </>
  )
}
