import React, { createContext, useContext, useState, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useFirebase } from '../core/ProvideFirebase';
import { Loading } from '../core/Loading'

const eventContext = createContext()

export const useEventData = () => useContext(eventContext)

const useInitEventData = (event_short) => {
  const { firestore } = useFirebase()
  const [value, loading, error] = useDocumentDataOnce(firestore.doc(`events/${event_short}`))

  console.log(value, loading, error)
  return {
    loading,
    exists: !error && !!value,
    eventPath: event_short,
    ...value
  }
}

export const ProvideEventData = ({children}) => {
  const { event_short } = useParams()

  const {loading, exists, ...eventData} = useInitEventData(event_short)

  if (loading) {
    return (<Loading />)
  }
  if (!exists) {
    return (
      <Navigate to="/" />
    )
  }
  
  return (
    <eventContext.Provider value={eventData}>
      {children}
    </eventContext.Provider>
  )
}
