import React from 'react'
import { useAuth } from './ProvideAuth'
import Button from '@material-ui/core/Button'

const UserData = () => {
  const { user, signOut } = useAuth()
  console.log('User is', user)
  return (
    <div>
      Logged in as: <strong>{user.email}</strong>
      <Button onClick={() => signOut()}>Log Out</Button>
    </div>
  )
}

export const UserInfoAndLogin = () => {
  const { loggedIn, setLoginModalOpen } = useAuth()
  if (loggedIn) {
    return (<UserData />)
  }
  return (
    <Button onClick={() => setLoginModalOpen(true)}>Log In</Button>
  )
}