import React from 'react'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Footer } from './Footer'
import { Outlet } from 'react-router-dom'

const styles = theme => ({
  body: {
    width: '100%',
    marginBottom: '60px'
  },
  footer: {
    width: '100%',
    height: '30px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    paddingTop: '3px',
    borderTop: '1px solid black',
    backgroundColor: '#d5d5db',
    textAlign: 'center'
  }
})

const _Layout = ({classes}) => (
  <>
    <div className={classes.body}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </div>
    <div className={classes.footer}>
      <Footer />
    </div>
  </>
)

export const Layout = withStyles(styles)(_Layout)