import React, { createContext, useContext, useState, useEffect } from 'react'
import { useFirebase } from './ProvideFirebase'
import { getAuth, GoogleAuthProvider, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import GoogleLoginButton from 'react-google-button'

const authContext = createContext()

export const useAuth = () => useContext(authContext)

export const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
  ],
};


const useInitAuthHooks = (firebaseAuth) => {
  const [customClaims, setCustomClaims] = useState({})
  const [claimsLoaded, setClaimsLoaded] = useState(false)
  const [user, loading, error] = useAuthState(firebaseAuth);
  console.log('User in init is ', user)

  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const wrappedSignOut = () => signOut(firebaseAuth)
  useEffect(() => {
    if (!user) { return }
    console.log('Received user in state change: ', user)
    user.getIdTokenResult().then(idTokenResult => {
      console.log('Received claims: ', idTokenResult.claims)
      setCustomClaims(idTokenResult.claims)
      setClaimsLoaded(true)
    })

  }, [user])

  return {
    user,
    customClaims,
    loading: loading || !claimsLoaded,
    error,
    loginModalOpen,
    setLoginModalOpen,
    firebaseAuth,
    signOut: wrappedSignOut,
    loggedIn: !!user && !loading
  }
}

const ProvideAuthData = ({firebaseAuth, children}) => {
  const authData = useInitAuthHooks(firebaseAuth)
  return (
    <authContext.Provider value={authData}>
      {children}
    </authContext.Provider>
  )
}

export const ProvideAuth = ({children}) => {
  const { firebaseAuth } = useFirebase()
  if (!firebaseAuth) {
    return
  }
  return (
    <ProvideAuthData firebaseAuth={firebaseAuth}>
      {children}
    </ProvideAuthData>
  )
}