import React from 'react'
import Modal from 'react-modal'
import { useAuth, uiConfig } from './ProvideAuth'
import { StyledFirebaseAuth } from 'react-firebaseui'

Modal.setAppElement('#root')
const modalStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    zIndex                : 1
  }
};

export const LoginModal = () => {
  const {loginModalOpen, setLoginModalOpen, firebaseAuth} = useAuth()
  return (
    <Modal
      isOpen={loginModalOpen}
      style={modalStyles}
      onRequestClose={() => {
        setLoginModalOpen(false)
      }}
      shouldCloseOnOverlayClick
    >
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />

    </Modal>
  )
}