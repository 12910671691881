import React, { useEffect } from 'react'
import { useAuth } from './ProvideAuth'
import { Route, Navigate } from 'react-router-dom'
import { Loading } from './Loading'

export const RequireAuth = ({ children }) => {
  const auth = useAuth()
  console.log('In Require Auth', auth.customClaims)
  useEffect(() => {
    if (!auth.user && !auth.loading) {
      auth.setLoginModalOpen(true)
    }
  }, [auth.user, auth.loading])
  if (!auth.user || auth.loading) {
    return <Loading />
  }
  if (auth.customClaims && auth.customClaims.admin) {
    console.log('Trying to render children')
    return children
  }
  console.log('Trying to redirect')
  return (<Navigate to="/" />)
}
