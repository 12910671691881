import React, { useEffect, useState, createContext, useContext } from 'react'
import { useSettings } from './ProvideSettings'
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/analytics"

// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

const firebaseContext = createContext()

export const useFirebase = () => useContext(firebaseContext)

const useInitFirebase = () => {
  const settings = useSettings()
  const [firebaseApp, setFirebaseApp] = useState(null)
  const [firebaseAnalytics, setFirebaseAnalytics] = useState(null)
  const [firebaseAuth, setFirebaseAuth] = useState(null)
  const [firebaseStorage, setFirebaseStorage] = useState(null)
  const [firestore, setFirestore] = useState(null)
  useEffect(() => {
    if (!settings) { return null }
    if (!window.mcrFirebaseInstance) {
      window.mcrFirebaseInstance = firebase.initializeApp(settings.FIREBASE_CFG)
    }
    setFirebaseApp(window.mcrFirebaseInstance)
    setFirebaseAnalytics(window.mcrFirebaseInstance.analytics())
    setFirestore(window.mcrFirebaseInstance.firestore())
    setFirebaseAuth(window.mcrFirebaseInstance.auth())
    setFirebaseStorage(window.mcrFirebaseInstance.storage())
  }, [settings, setFirebaseApp, setFirebaseAnalytics, setFirestore, setFirebaseAuth, setFirebaseStorage])
  return {
    firebaseApp,
    firestore,
    firebaseAuth,
    firebaseStorage,
    analytics: firebaseAnalytics
  }
}

export const ProvideFirebase = ({children}) => {
  const {firebaseApp, ...rest} = useInitFirebase()
  if (!firebaseApp) {
    return null
  }
  return (
    <firebaseContext.Provider value={{firebaseApp, ...rest}}>
      {children}
    </firebaseContext.Provider>
  )
}

