import React from 'react'
import {
  Admin,
  Resource
} from 'react-admin'

import { FirebaseDataProvider } from 'react-admin-firebase';
import { useFirebase } from '../core/ProvideFirebase';
import { EventsList, EventsCreate, EventsEdit } from './Events'


export const PresentationAdmin = () => {
  const { firebaseApp } = useFirebase()
  const dataProvider = FirebaseDataProvider({}, { app: firebaseApp });

  console.log('In Admin')

  return (
    <Admin dataProvider={dataProvider}>
      <Resource name="events" list={EventsList} create={EventsCreate} edit={EventsEdit} />
    </Admin>
  )
}