export const displayPresenterName = (record) => {
  const {
    presenter: {
      firstName = '',
      lastName = ''
    } = {}
  } = record
  if (firstName === '') {
    return lastName
  }
  return `${lastName}, ${firstName}`
}