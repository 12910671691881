import React, { useEffect, useState, createContext, useContext } from 'react'

const settingsContext = createContext()

export const useSettings = () => useContext(settingsContext)

const useInitSettings = () => {
  const [settings, setSettings] = useState(null)
  useEffect(() => {
    fetch('/settings.json').then(val => val.json()).then(data => {
      setSettings(data)
    })
  }, [setSettings])
  return settings
}

export const ProvideSettings = ({children}) => {
  const settings = useInitSettings()
  if (!settings) {
    return null
  }
  return (
    <settingsContext.Provider value={settings}>
      {children}
    </settingsContext.Provider>
  )
}

